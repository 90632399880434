import React, {
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "./App.scss";
import Navbar from "./flights/components/Navbar/Navbar";
import Footer from "./flights/components/Footer/index";
import { Loader } from "@mantine/core";
import ReactGA from "react-ga";
import { MyContext } from "./app/MyProvider";
import Landing from "./flights/pages/Landing/index";
import axios from "axios";
import { BlockedCountries, LocationBlocker } from "./utils/LocationBlocker";
import { getUserData } from "./api/auth";
import { getCookie, setCookie } from "./utils/cookies";
const CookiesBanner = React.lazy(
  () => import("./GlobalComponents/cookieBanner")
);
const BookingSurvey = React.lazy(() => import("./common/Survey"));
const Home = React.lazy(() => import("./flights/pages/Home/index"));
const AdminRoute = React.lazy(() => import("./AdminRoute"));
const SearchFlights = React.lazy(
  () => import("./flights/pages/SearchFlights/index")
);

const TicketConfirmation = React.lazy(
  () => import("./flights/pages/TicketConfirmation/TicketConfirmation")
);
const TicketPreview = React.lazy(
  () => import("./flights/pages/TicketPreview/TicketPreview")
);
const Support = React.lazy(() => import("./common/support/index"));
const Article = React.lazy(() => import("./blog/Article/index"));
const SupportList = React.lazy(() => import("./common/SupportList/index"));
const MyBooking = React.lazy(() => import("./flights/pages/MyBooking/index"));
const BookingDetails = React.lazy(
  () => import("./flights/pages/BookingDetails/index")
);
const SupportDetail = React.lazy(
  () => import("./flights/pages/SupportDetail/index")
);
const ReviewsPage = React.lazy(() => import("./flights/pages/Reviews"));

// Hotel Pages start//

const HotelHome = React.lazy(() => import("./hotels/pages/home"));
const HotelDetails = React.lazy(() => import("./hotels/pages/details"));

// Hotel Pages end//
const TRACKING_ID = "G-3M6E7372M9";
ReactGA.initialize(TRACKING_ID);

function App() {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [userCountry, setUserCountry] = useState("");
  const [userCity, setUserCity] = useState("");
  const [cookie, setCookieState] = useState(false);
  const { setLoggedIn, setUserData, userData, loggedIn } =
    useContext(MyContext);
  ReactGA.pageview(window.location.pathname + window.location.search);

  const fetchUserData = useCallback(async () => {
    await getUserData()
      .then((v) => {
        setUserData(v.data.data);
        setLoggedIn({ loading: false, login: true });
      })
      .catch((err) => {
        console.log(err);
        setLoggedIn((prev) => ({ ...prev, loading: false }));
      });
  }, [setLoggedIn, setUserData]);

  const onAccept = () => {
    setCookie("allow-cookie", "yes", 365);
    setCookieState(false);
  };

  const onDecline = () => {
    setCookie("allow-cookie", "no", 365);
    setCookieState(false);
  };

  useEffect(() => {
    const fetchCountry = async () => {
      try {
        const response = await axios.get(
          "https://geolocation.onetrust.com/cookieconsentpub/v1/geo/location"
        );
        const countryCode = response.data.country;
        const city = response.data.state;

        setUserCountry(countryCode);
        setUserCity(city);
      } catch (error) {
        console.error("Error fetching country:", error);
      }
    };

    fetchCountry();
    fetchUserData();
    let cookieData = getCookie("allow-cookie");
    if (cookieData !== "yes" && cookieData !== "no") {
      setCookieState(true);
    }
  }, [fetchUserData]);

  const isCountryBlocked = BlockedCountries.includes(userCountry);
  const isCityBlocked = BlockedCountries.includes(userCity);

  return (
    <>
      {isCountryBlocked || isCityBlocked ? (
        <LocationBlocker userCountry={userCountry} userCity={userCity} />
      ) : (
        <>
          {" "}
          <a className="skip-to-main-content-link" href="#container">
            Skip to main content
          </a>
          <div
            className={`app ${
              splitLocation[1]?.toLowerCase() === "" ||
              splitLocation[1]?.toLowerCase() === "home"
                ? "home--page"
                : ""
            }`}
          >
            <GoogleOAuthProvider
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            >
              {pathname !== "/landing" && <Navbar />}
            </GoogleOAuthProvider>
            <div id="container">
              <Suspense
                fallback={
                  <div className="whole--loading">
                    <Loader />
                  </div>
                }
              >
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/landing" element={<Landing />} />
                  <Route path="/search-flights" element={<SearchFlights />} />
                  {/* Tickets */}
                  <Route path="/preview" element={<TicketPreview />} />
                  <Route
                    path="/confirmation"
                    element={<TicketConfirmation />}
                  />
                  {/* ?Support */}
                  <Route path="/newsupport" element={<Support />} />
                  <Route path="/supportdetails" element={<SupportDetail />} />
                  <Route path="/supportlist" element={<SupportList />} />
                  {/* Mybooking */}
                  <Route path="/mybooking" element={<MyBooking />} />
                  <Route
                    path="/mybooking/bookingdetails"
                    element={<BookingDetails />}
                  />
                  <Route path="/article/:slug" element={<Article />} />
                  <Route path="/booking/survey" element={<BookingSurvey />} />

                  <Route
                    path="/flight/reviews/:carrier"
                    element={<ReviewsPage />}
                  />

                  {/* Hotel routing start */}

                  <Route path="/hotel" element={<Outlet />}>
                    <Route path="home" element={<HotelHome />} />
                    <Route path="details" element={<HotelDetails />} />
                  </Route>

                  {/* Hotel routing end */}
                </Routes>
              </Suspense>
              <Suspense
                fallback={
                  <div className="whole--loading">
                    <Loader />
                  </div>
                }
              >
                {!loggedIn.loading ? (
                  userData?.role === "ADMIN" ? (
                    <AdminRoute />
                  ) : (
                    <Routes>
                      <Route path="/admin/*" element={<Navigate to={"/"} />} />
                    </Routes>
                  )
                ) : null}
              </Suspense>
            </div>
            <Footer />
            {cookie && (
              <CookiesBanner onAccept={onAccept} onDecline={onDecline} />
            )}
          </div>
        </>
      )}
    </>
  );
}

export default React.memo(App);
