import axios from "axios";
import { endpoint } from "../config/dev";
import { refreshAccessToken } from "./auth";

export type Res<T> = {
  success: boolean;
  data: T;
};

const instance = axios.create({
  baseURL: endpoint,
  withCredentials: true,
  timeout: 25 * 1000,
});

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    // unauthorized retry logic
    if (
      !["/auth/refresh", "/auth/signin"].some(
        (substring) => err?.config?.url?.includes(substring)
      ) &&
      err?.response?.status === 401
    ) {
      return new Promise((resolve, reject) => {
        refreshAccessToken()
          .then(() => {
            resolve(instance(err?.config));
          })
          .catch(() => {
            reject(err);
          });
      });
    } else {
      return Promise.reject(err);
    }
  }
);

export default instance;
