import React from "react";
import { Link } from "react-router-dom";

interface NavMenuProps {
  link: string;
  name: string;
  currentPath: string;
}

const NavMenu: React.FC<NavMenuProps> = ({ link, name, currentPath }) => {
  return (
    <li className={currentPath === `/${link}` ? "active" : ""}>
      <Link
        style={{ textDecoration: "none", color: "inherit" }}
        to={`/${link}`}
      >
        {name}
      </Link>
    </li>
  );
};

export default NavMenu;
