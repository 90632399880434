import React, { createContext, useContext, useState } from "react";

const TravelerDataContext = createContext();

export const useTravelerDataContext = () => useContext(TravelerDataContext);

export const TravelerDataProvider = ({ children }) => {
  const [travelerData, setTravelerData] = useState({});

  return (
    <TravelerDataContext.Provider value={{ travelerData, setTravelerData }}>
      {children}
    </TravelerDataContext.Provider>
  );
};
