import React from "react";
import "./IsCountryBlocked.scss";

interface IsCountryBlockedProps {
  place: string;
}

const IsCountryBlocked: React.FC<IsCountryBlockedProps> = ({ place }) => {
  return (
    <section className="website-blocker">
      <nav className="Navbar">
        <div className="Logo">Magicvisor</div>
      </nav>
      <article>
        <p>Dear Travelers,</p>
        <p>
          Thank you for choosing Magicvisor. We regret to inform you that our
          services are currently unavailable in your country, and we apologize
          for any inconvenience caused to travelers.
        </p>
      </article>
    </section>
  );
};

export default IsCountryBlocked;
