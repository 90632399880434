import { UserDataProps } from "../app/MyProvider";
import axios, { Res } from "./API";

const signinApi = async (token: string) => {
  return (
    await axios.post<Res<UserDataProps>>("/auth/signin", {
      token: token,
    })
  ).data;
};

const getUserData = async () => {
  return await axios.get<Res<UserDataProps>>("/auth/token");
};

const refreshAccessToken = async () => {
  return await axios.get("/auth/refresh");
};

const logoutApi = async () => {
  return await axios.delete("/auth/logout");
};

export { signinApi, getUserData, refreshAccessToken, logoutApi };
