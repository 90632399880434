import React from "react";
import Groups from "../../../blog/Groups/index";
import "./Footer.scss";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { Container } from "@mantine/core";
import { socialLinks } from "../../../constants/socialIcons";

const Footer = () => {
  return (
    <footer>
      <Container size={"lg"}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px 0",
            borderBottom: "1px solid hsla(0,0%,100%,.3)",
            flexWrap: "wrap",
          }}
        >
          <div style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
            Easy access
          </div>
          <div
            style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}
          >
            <span
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
              }}
            >
              Connect with us
            </span>
            <div className="social-media">
              {socialLinks.map((link, index) => (
                <Link key={index} target="_blank" to={link.href}>
                  {link.svg}
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="footer-links--wrapper">
          <div className="footer-links--category">
            <FooterTitle>Company</FooterTitle>
            <Groups groupName={"company"} />
          </div>
          <div className="footer-links--category">
            <FooterTitle>Explore</FooterTitle>
            <Groups groupName={"explore"} />
          </div>
          <div className="footer-links--category">
            <FooterTitle>Policies</FooterTitle>
            <Groups groupName={"policies"} />
          </div>
          <div className="footer-links--category">
            <FooterTitle>Help</FooterTitle>
            <Groups groupName={"help"} />
          </div>
        </div>
        <div className="copyright">
          {/* <desc>
          &copy; Magicviser Magicvisor, LLC. is not responsible for content on
          external Websites.
        </desc> */}
          <br />
          <desc>
            &copy; 2023 Magicvisor, LLC. All rights reserved.
            {/* Magicvisor and the
          Airplane Logo are trademarks or registered trademarks of Magicvisor,
          LLC. CST# 2029030-50. */}
          </desc>
        </div>
      </Container>
    </footer>
  );
};

const FooterTitle = styled.p`
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 6px;
  margin-top: 10px;
  text-transform: uppercase;
`;

export default Footer;
