import React, { FC, useEffect, useState } from "react";
import axios from "../../api/API";
import { ArticleType, GroupsProps } from "../Types/articleType";

const Groups: FC<GroupsProps> = ({ groupName }) => {
  const [articles, setArticles] = useState<ArticleType[]>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios(`/cms/findbygroup?group=${groupName}`);

        const data = await response.data;
        setArticles(data);
      } catch (error) {
        // Handle error, e.g., log it or set an error state
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [groupName]);

  return (
    <ul className="groups-container">
      {articles?.map((article, index) => {
        return (
          <li key={index}>
            <a
              target="_blank"
              href={`/article/${article.slug}`}
              rel="noreferrer"
            >
              {article.title}
            </a>
          </li>
        );
      })}
    </ul>
  );
};

export default Groups;
