import React, { useState } from "react";
import "./Landing.scss";
import { endpoint } from "../../../config/dev";
import { Link } from "react-router-dom";
import axios from "axios";
import { socialLinks } from "../../../constants/socialIcons";

const TickImg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="14"
    viewBox="0 0 17 14"
    fill="none"
  >
    <path
      d="M2 6.63476L6.11171 12.4069"
      stroke="#404040"
      stroke-width="2.71848"
      stroke-linecap="round"
    />
    <path
      d="M6.11456 12.437L15.5257 1.56304"
      stroke="#404040"
      stroke-width="2.71848"
      stroke-linecap="round"
    />
  </svg>
);

const Landing = () => {
  const [email, setEmail] = useState<string>();
  const [success, setSuccess] = useState<null | string>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      axios
        .post(`${endpoint}/subscriber/save`, {
          emailId: email,
        })
        .then((response: any) => {
          setSuccess(
            response.msg
              ? response.msg
              : "Hooray!! You are added to the waiting list"
          );
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error:", error);
          setErrorMessage(error || "Enter a valid email");
        });
    } catch (error) {
      console.error(error);
      setErrorMessage("Error sending email");
    } finally {
      console.log("Complete");
    }
  };

  return (
    <div className="landing-page">
      <div className="hero-left-side">
        <div className="">
          <h2>flight booking</h2>
          <h3>made easy</h3>
        </div>
        <div>
          <h4>Seamless Booking, Endless Possibilities</h4>
          <p>
            <TickImg /> Plan smarter trips: Predict weather and flight delays in
            one app
          </p>
          <p>
            <TickImg /> Easy to search, filter and book flights
          </p>
          <p>
            <TickImg /> Transparent pricing and no price gouging
          </p>
          <p>
            <TickImg /> Forecast flight delays
          </p>
        </div>
        {!success ? (
          <div className="email-input">
            <input
              required
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              className=""
              placeholder="Enter your email"
            />
            <button onClick={handleSubmit}>
              {loading ? "Adding..." : "Subscribe"}
            </button>{" "}
            :
          </div>
        ) : (
          <div>{success}</div>
        )}
        {errorMessage && <h6>{errorMessage}</h6>}
        <div className="social-media">
          {socialLinks.map((link, index) => (
            <Link key={index} target="_blank" to={link.href}>
              {link.svg}
            </Link>
          ))}
        </div>
      </div>
      <div className="hero-right-side">
        <div>
          <img src="/Images/landing-hero.svg" alt="hero" />
        </div>
      </div>
    </div>
  );
};

export default Landing;
