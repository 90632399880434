export const endpoint = process.env.REACT_APP_API;

const config = {
  itineraryApi: "/itinerary",
  contactusApi: "/contactus",
  contactusthread: "/contactus-thread",
  subscriberApi: "/subscriber",
  templateApi: "/message/template",
};

export default config;
