import React, { useState, useEffect, useContext } from "react";
import "./Navbar.scss";
import NavMenu from "./NavMenu";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { Dropdown, Form } from "react-bootstrap";
import { MyContext } from "../../../app/MyProvider";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CurrencyJson } from "./CurrencyJson";
import PubSub from "pubsub-js";
import langCurrencyMap from "../../../constants/langCurrency";
import { logoutApi, signinApi } from "../../../api/auth";
import { Button, Menu, Skeleton } from "@mantine/core";

function Navbar() {
  const { setUserData, userData, loggedIn, setLoggedIn } =
    useContext(MyContext);
  const [userLanguage, setUserLanguage] = useState<string>("");

  const defaultCurrency = langCurrencyMap[userLanguage];
  const defaultCurrencyUpperCase = defaultCurrency?.toUpperCase();
  const [muiltiCurrency, setMuiltiCurrency] = useState(
    localStorage.getItem("multiCurrency") || null
  );
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchUserLanguage = async () => {
      const language = navigator.language;
      setUserLanguage(language);
      setLoading(false);
    };
    PubSub.subscribe("currencyChange", (newCurrency) => {
      console.log("Currency changed to:", newCurrency);
    });
    fetchUserLanguage();
    return () => {
      PubSub.unsubscribe("currencyChange");
    };
  }, []);

  useEffect(() => {
    if (!loading) {
      setMuiltiCurrency(
        localStorage.getItem("multiCurrency") || defaultCurrencyUpperCase
      );
    }
  }, [defaultCurrencyUpperCase, loading, setUserLanguage]);

  // Navbar location state
  const location = useLocation();

  const currencyOptions = Object.entries(CurrencyJson).map(([code, name]) => {
    return (
      <option
        key={code}
        selected={localStorage.getItem("multiCurrency") === code}
        value={code}
      >
        {code} - {name}
      </option>
    );
  });

  useEffect(() => {
    const language = navigator.language;
    setUserLanguage(language);
  }, []);

  const handleLogin = async (googleData: CredentialResponse) => {
    try {
      const res = await signinApi(googleData.credential);
      setUserData(res.data);
      setLoggedIn({ loading: false, login: true });
    } catch (error) {
      console.error("Login failed", error);
    }
  };

  const handleLogout = async () => {
    try {
      await logoutApi();
      setLoggedIn({ loading: false, login: false });
      setUserData(null);
    } catch (error) {
      console.error("Logout failed", error);
    }
  };

  const baseClickables = [
    { name: "Bookings", link: "mybooking" },
    { name: "Support", link: "supportlist" },
  ];
  const adminClickables = [
    {
      name: "Tickets & Invoices",
      link: "/admin/tickets-invoices",
    },
    {
      name: "Subscriber",
      link: "/subscriber",
    },
    {
      name: "Message",
      link: "/subscriber/message",
    },
    {
      name: "Communication",
      link: "/subscriber/message/communication",
    },
    {
      name: "CMS",
      link: "/admin/cms/preview",
    },
  ];
  const clickables = baseClickables;

  const navigation = useNavigate();

  return (
    <div className="Navbar">
      <Link to={"/"}>
        <div className="Logo">Magicvisor</div>
      </Link>
      <div className="NavMenu">{/* Render NavMenu items */} </div>
      <div className="NavbarLink">
        {userData && (
          <ul>
            {clickables.map((clickable, i) => {
              // if (clickable.isAdminShow) {
              return (
                <NavMenu
                  link={clickable.link}
                  currentPath={location.pathname}
                  name={clickable.name}
                />
              );
              // }
            })}
          </ul>
        )}
      </div>
      <div className="NavbarLink">
        <ul>
          <li>
            <Form.Select
              value={muiltiCurrency || defaultCurrencyUpperCase}
              onChange={(e) => {
                setMuiltiCurrency(e.target.value);
                localStorage.setItem("multiCurrency", e.target.value);
                PubSub.publish("currencyChange", e.target.value);
              }}
              className="Typeahead--Search-header"
              aria-label="Default select example"
            >
              {currencyOptions}
            </Form.Select>
          </li>
        </ul>
      </div>
      {userData?.role === "ADMIN" && (
        <div className="NavbarLink">
          <ul>
            <li role="button" className="">
              <Menu shadow="md" width={200}>
                <Menu.Target>
                  <Button variant="outline" color="orange">
                    Admin
                  </Button>
                </Menu.Target>

                <Menu.Dropdown>
                  {adminClickables.map((v) => (
                    <Menu.Item onClick={() => navigation(v.link)}>
                      {v.name}
                    </Menu.Item>
                  ))}
                </Menu.Dropdown>
              </Menu>
            </li>
          </ul>
        </div>
      )}
      <div className="Signup">
        {!loggedIn.loading ? (
          !loggedIn.login ? (
            <div className="GoogleSignup">
              <GoogleLogin
                allowed_parent_origin={
                  process.env.REACT_APP_NODE_ENV === "production"
                    ? null
                    : "http://localhost:3000/"
                }
                onSuccess={handleLogin}
                onError={() => {
                  console.log("Google login error");
                }}
                useOneTap={true}
              />
            </div>
          ) : (
            <div className="">
              <Dropdown className="Profile-dropdown">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  <img src={userData?.pic} alt="profile pic" className="pic" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )
        ) : (
          <Skeleton height={33} circle mb="xl" mt={20} />
        )}
      </div>
    </div>
  );
}

export default Navbar;
