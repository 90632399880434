import React from "react";
import IsCountryBlocked from "../common/isCountryBlock/index";

export const BlockedCountries = [
  "RU",
  "IR",
  "KP",
  "VE",
  "BY",
  "CU",
  "AF",
  "HK",
  "ET",
  "LY",
  "ML",
  "SO",
  "SY",
  "YE",
  "ZW",
  "SD",
];

export function LocationBlocker({ userCountry, userCity }) {
  const isCountryBlocked = BlockedCountries.includes(userCountry);
  const isCityBlocked = BlockedCountries.includes(userCity);

  if (isCountryBlocked || isCityBlocked) {
    return <IsCountryBlocked place={userCountry} />;
  }

  return null;
}
