const langCurrencyMap = {
  "en-US": "usd",
  "en-GB": "gbp",
  "en-AU": "aud",
  "en-CA": "cad",
  "en-SG": "sgd",
  "en-HK": "hkd",
  "en-NZ": "nzd",
  "fr-FR": "eur",
  "fr-CA": "cad",
  "es-ES": "eur",
  "es-MX": "mxn",
  "pt-BR": "brl",
  "pt-PT": "eur",
  "de-DE": "eur",
  "de-CH": "chf",
  "it-IT": "eur",
  "nl-NL": "eur",
  "nl-BE": "eur",
  "sv-SE": "sek",
  "da-DK": "dkk",
  "no-NO": "nok",
  "ja-JP": "jpy",
  "zh-CN": "cny",
  "zh-HK": "hkd",
  "zh-TW": "twd",
};

export default langCurrencyMap;
